import themeGet from "@styled-system/theme-get";
import styled from "styled-components";

const ExpertiseArea = styled.section`
  position: relative;
  background-color: #006be9;
  padding-top: 70px;
  padding-bottom: 50px;
  @media (max-width: 1600px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media (max-width: 1199px) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  @media (max-width: 375px) {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .expertiseTitle {
    text-align: left;
    font-weight: 500;
    width: 100%;
    color: white;
    @media (max-width: 1199px) {
      text-align: center;
    }
  }
  .button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    color: #00d4ff;
    font-size: 16px;
    position: relative;
    z-index: 1;

    &:hover {
      &:before {
        opacity: 0;
      }
      span {
        i {
          transform: translateX(2px);
        }
      }
    }
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      transition: opacity 0.4s ease;
    }
    &:before {
      background-image: transparent;
    }
    &:after {
      background-image: transparent;
      z-index: -1;
    }
    i {
      margin-left: 10px;
      position: relative;
      top: -1px;
      transition: transform 0.4s ease;
    }
    span {
      position: relative;
      display: flex;
      z-index: 2;
    }
  }
  .sectionImage {
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: none;
    animation: var(--DashboardFadeIn) 0.8s linear;
    @media (max-width: 1199px) {
      max-width: 90%;
      position: relative;
      bottom: auto;
      right: auto;
      border-radius: 15px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .postWrap,
  .blockTitle {
    margin-right: 30px;
    @media (max-width: 1600px) {
      padding-left: 0px;
    }
    @media (max-width: 1199px) {
      padding-left: 0;
      padding-top: 0px;
    }
    @media (max-width: 425px) {
      padding-top: 20px;
    }
  }
  .postWrap {
    @media (max-width: 1199px) {
      padding-top: 0;
    }
  }
  .blockTitle {
    @media (max-width: 1199px) {
      max-width: 100%;
      margin-left: auto;
      text-align: center;
      margin-right: auto;
    }
    @media (max-width: 480px) {
      max-width: 100%;
      text-align: left;
    }
    h2 {
      font-weight: 500;
      font-size: 40px;
      line-height: 1.3;
      letter-spacing: -0.02em;
      color: #ffffff;
      margin: 0;
      margin-bottom: 20px;
      @media (max-width: 1600px) {
        font-size: 34px;
        line-height: 1.21;
      }
      @media (max-width: 480px) {
        font-size: 26px;
      }
      @media (max-width: 375px) {
        font-size: 22px;
      }
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 2;
      color: #ffffff;
      margin-bottom: 30px;
      @media (max-width: 1600px) {
        line-height: 1.625;
      }
    }
  }
  .postWrap {
    padding-bottom: 80px;
    @media (max-width: 1600px) {
      padding-bottom: 100px;
    }
    @media (max-width: 1199px) {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
    @media (max-width: 1024px) {
      padding-bottom: 40px;
    }
    @media (max-width: 480px) {
      max-width: 100%;
    }
  }
  .post {
    display: flex;
    padding: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    cursor: pointer;
    transition: all 500ms ease;
    @media (max-width: 480px) {
      // flex-direction: column;
      padding: 10px;
      border-radius: 10px;
    }
    &.active {
      border: 2px solid rgba(255, 255, 255, 1);
    }
    + .post {
      margin-top: 20px;
    }
    .image {
      margin-right: 20px;
      flex-shrink: 0;
      @media (max-width: 480px) {
        margin-right: 10px;
        // margin-bottom: 20px;
        max-width: 20%;
        flex: 0 0 20%;
      }
      img {
        width: 80px;
        // @media (max-width: 425px) {
        //   margin-left: auto;
        //   margin-right: auto;
        //   display: block;
        // }
      }
    }
    .postContent {
      @media (max-width: 425px) {
        // text-align: center;
      }
      h3 {
        font-weight: 500;
        font-size: 17px;
        line-height: 1.76;
        letter-spacing: -0.01em;
        color: #ffffff;
        margin: 0;
        margin-bottom: 5px;
        @media (max-width: 1600px) {
          font-size: 16px;
        }
      }
      p {
        margin: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 1.56;
        color: #ffffff;
        @media (max-width: 1600px) {
          font-size: 15px;
        }
      }
    }
  }
`;

export default ExpertiseArea;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
export const Col = styled.div`
  flex: 0 0 50%;
  @media (max-width: 1199px) {
    flex: 0 0 100%;
  }
  &.image {
    position: relative;
    flex: 0 0 60%;
    @media (min-width: 1850px) and (max-width: 2500px) {
      position: absolute;
      left: 0;
      bottom: -5px;
    }
    @media (max-width: 1199px) {
      flex: 0 0 100%;
    }
  }
`;
