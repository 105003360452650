// import Box from "common/components/Box";
import Heading from "../../../common/components/Heading";
// import Switch from "common/components/Switch";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { SERVICES_DATA } from "../../../common/data/WebApp";
// import Link from "next/link";
// import Link from "../../../components/Link";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import { androidDone } from "react-icons-kit/ionicons/androidDone";
import ProductArea, {
  CardBody,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  TopHeading,
} from "./product.style";

const SectionServices = () => {
  return (
    <ProductArea id="product_section">
      <Container className="Container">
        <TopHeading>
          <Heading as="h2" content="Services" />
        </TopHeading>

        <Row>
          {SERVICES_DATA?.map(
            (
              {
                product,
                title,
                image,
                price,
                tagline,
                planLabel,
                options,
                button,
              },
              index
            ) => (
              <>
                <Col key={`pricing-card-key-${index}`}>
                  <PriceCard className={product === 1 ? "recommended" : " "}>
                    <CardTop>
                      {image !== "" ? (
                        <img
                          className="productImage"
                          src={image}
                          alt="product-logo"
                        />
                      ) : (
                        <Heading as="h3" content={title} />
                      )}
                    </CardTop>
                    <CardBody>
                      <Heading as="h4" content={title} />
                    </CardBody>

                    {/* <CardFooter>
                      <Link className="priceBtn" to={button?.link}>
                        <span>
                          {button?.label}
                          <Icon size={18} icon={androidArrowForward} />
                        </span>
                      </Link>
                    </CardFooter> */}
                  </PriceCard>
                </Col>
              </>
            )
          )}
        </Row>
        <Row>
          <Link className="LearnMore" to="/services">
            <span>
              Learn More
              <Icon size={18} icon={androidArrowForward} />
            </span>
          </Link>
        </Row>
      </Container>
    </ProductArea>
  );
};

export default SectionServices;
