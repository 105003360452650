import Banner from "../containers/WebApp/Banner";
import GetInTouch from "../containers/WebApp/GetInTouch";
import Intro from "../containers/WebApp/Intro";
import SectionSolutions from "../containers/WebApp/SectionSolutions";
import SectionServices from "../containers/WebApp/SectionServices";
import Expertise from "../containers/WebApp/Expertise";
import SEO from "../components/SEO";
import ContactForm from "../containers/WebApp/ContactForm/Section";

import React from "react";

const WebApp = () => {
  return (
    <>
      <SEO title="Home" />
      <Banner />
      <Intro />
      <Expertise />
      <SectionSolutions />
      <SectionServices />
      <ContactForm />
    </>
  );
};

export default WebApp;
