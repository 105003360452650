import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { EXPERTISE_DATA } from "../../../common/data/WebApp";
// import Image from "next/image";
import React, { useState } from "react";
// import Link from "next/link";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";

import ExpertiseArea, { Col, Row } from "./expertise.style";
const Expertise = () => {
  const [tab, setTab] = useState({
    toggle: "tab-1",
  });
  const { sectionImage, blockTitle, posts, button } = EXPERTISE_DATA;
  const { title, text, title2 } = blockTitle;
  const handleClick = (tabName) => {
    setTab({
      ...tab,
      toggle: tabName,
    });
  };
  return (
    <ExpertiseArea id="dashboard_section">
      <Container>
        <Row>
          <Heading className="expertiseTitle" content={title} />
        </Row>
        <Row>
          <Col className="content">
            <Box className="blockTitle">
              <Text as="p" content={posts[0].text} />
            </Box>
          </Col>
          <Col className="content">
            <Box className="blockTitle">
              <Text as="p" content={posts[1].text} />
              {/* <a href={button.link}>
                <a className="button">
                  <span>
                    {button.label}
                    <Icon icon={androidArrowForward} size={16} />
                  </span>
                </a>
              </a> */}
            </Box>
          </Col>
        </Row>
      </Container>
    </ExpertiseArea>
  );
};

export default Expertise;
