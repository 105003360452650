import styled from "styled-components";

const GetInTouchArea = styled.section`
  background-color: #00d4ff;
  padding: 100px;
  @media (max-width: 768px) {
    padding-top: 70px;
    padding-bottom: 50px;
  }
  .container {
    text-align: center;
  }
  h4 {
    margin: 0;
    font-weight: normal;
    font-size: 24px;
    line-height: 1.75;
    text-align: center;
    letter-spacing: -0.015em;
    color: #0f2137;
    background-color: #f3f7fb;
    display: inline-block;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    top: -15px;
  }
  .imageWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    opacity: 0.6;
    margin-top: 45px;
    @media (max-width: 851px) {
      // justify-content: center;
      // margin-left: -30px;
      // margin-top: 25px;
      // img {
      //   margin-left: 30px;
      //   margin-bottom: 20px;
      // }
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
    }
    @media (max-width: 767px) {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
    }
  }
  .client-image-wrapper {
    width: 170px;
    cursor: pointer;
    // &:hover {
    //   animation: var(--wobbleVertical);
    // }
    @media (max-width: 767px) {
      width: 80px;
    }
  }
`;

export default GetInTouchArea;
