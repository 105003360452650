import Box from "../../../common/components/Box";
import Heading from "../../../common/components/Heading";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { BANNER_DATA } from "../../../common/data/WebApp";
// import Image from "next/image";

import { Link } from "gatsby";
import React from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import Typewriter from "typewriter-effect";
import BannerArea, { Col, TypewriterWrapper } from "./banner.style";

const Banner = () => {
  const { title, text, button, image, tagline } = BANNER_DATA;

  return (
    <BannerArea id="banner_section">
      <Container className="Container">
        <Col>
          <TypewriterWrapper>
            <Typewriter
              className="typewriterLabel"
              options={{
                strings: title,
                autoStart: true,
                loop: false,
                cursor: "_",
              }}
            />
          </TypewriterWrapper>
          <Text as="p" content={text} />
        </Col>
      </Container>
      <Box className="bannerImage">
        {image.map(({ src }, index) => (
          <img src={src} alt="" key={`banner-image-key-${index}`} />
        ))}
      </Box>
    </BannerArea>
  );
};

export default Banner;
