// import Box from "common/components/Box";
import Heading from "../../../common/components/Heading";
// import Switch from "common/components/Switch";
import Text from "../../../common/components/Text";
import Container from "../../../common/components/UI/Container";
import { PRODUCTS_DATA } from "../../../common/data/WebApp";
// import Link from "next/link";
// import Link from "../../../components/Link";
import { Link } from "gatsby";
import React, { useState } from "react";
import { Icon } from "react-icons-kit";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import { androidDone } from "react-icons-kit/ionicons/androidDone";
import ProductArea, {
  CardBody,
  CardFooter,
  CardTop,
  Col,
  PriceCard,
  PricingAmount,
  Row,
  TopHeading,
} from "./product.style";

const SectionSolutions = () => {
  return (
    <ProductArea id="product_section">
      <Container className="Container">
        <Row>
          <TopHeading>
            <Heading as="h2" content="Solutions" />
          </TopHeading>
        </Row>
        <Row>
          {PRODUCTS_DATA?.map(
            (
              {
                product,
                title,
                image,
                price,
                tagline,
                planLabel,
                options,
                button,
              },
              index
            ) => (
              <Col key={`pricing-card-key-${index}`}>
                <PriceCard className={product === 1 ? "recommended" : " "}>
                  <CardTop>
                    {image !== "" ? (
                      <img
                        className="productImage"
                        src={image}
                        alt="product-logo"
                      />
                    ) : (
                      <Heading as="h3" content={title} />
                    )}
                  </CardTop>

                  <CardFooter>
                    <Link className="priceBtn" to={button?.link}>
                      <span>
                        {button?.label}
                        <Icon size={18} icon={androidArrowForward} />
                      </span>
                    </Link>
                  </CardFooter>
                </PriceCard>
              </Col>
            )
          )}
        </Row>
      </Container>
    </ProductArea>
  );
};

export default SectionSolutions;
